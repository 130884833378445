import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// Popup dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// table to display users
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// combobox to select users to be added
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

import PopupNotification from 'components/General/PopupNotification';

import * as Mui from '@material-ui/core';
import styled from "styled-components";
import useSWR, { mutate } from "swr";

import {
  useUserGroupByGroupId,
  updateUserGroup
} from "services/UserGroup";
import { useGetUsersByMyGroup, useUsers } from "services/User";

const Button = styled(Mui.Button)`
  width: 9em;
  padding: 1px 10px;
  text-transform: none;
`;

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));


export default function ManageUsersBtn({ group }) {
  //console.log('grouppppp',group)
  const classes = useStyles();

  // get user-group(db table) info
  const {
    userGroupData,
    userGroupLoading,
    userGroupError
  } = useUserGroupByGroupId(group.id);
  //console.log('userGroupData', userGroupData)

  // get users by login user's group
  const { usersData, usersLoading, usersError } = useGetUsersByMyGroup();
  //console.log('usersData', usersData)

  // get all users for dropdown options
  const { allUserData, allUserLoading, allUserError } = useUsers();
  //console.log('all users', allUserData)

  const [newUserGroup, setNewUserGroup] = useState([])
  const [open, setOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState('');

  const handleClickOpen = () => {
    setNewUserGroup(userGroupData)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveUserGroup = async (data) => {
    if (!data || data.length === 0) return
    //console.log('updating...', data)
    const response = await updateUserGroup(data)
    //console.log(response)
    mutate(`${process.env.REACT_APP_API}/api/user-group/${data.groupId}`);
  }

  /* render ui */
  const popupMessasge = (data) => {
    const { message, open } = data
    return (<PopupNotification open={open} setOpen={setPopup} message={message} />)
  }

  const userTable = (data) => {
    const { userGroup } = data
    //console.log('table gggg',userGroup)

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">#</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userGroup?.map(({ user }, i) => (
              <TableRow key={user.id}>
                <TableCell align="left">{i + 1}</TableCell>
                <TableCell align="left">{`${user.lastName || ''} ${user.firstName || ''}`}</TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">
                  <DeleteIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setNewUserGroup(newUserGroup.filter(item => {
                        return item.user.id !== userGroup[i].userId
                      }))
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const usersSelectionDropdown = (data) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { users, group } = data
    //console.log('uuuu',users)
    //console.log('ggggg',userGroupData)
    const userOptions = users.filter(user =>
      !userGroupData.find(({ userId }) => userId === user.id)
    )

    return (
      <Autocomplete
        multiple
        id="users-selection"
        size="small"
        onChange={(e, selectedUsers) => {
          const data = [
            ...userGroupData,
            ...selectedUsers.map(item => ({ userId: item.id, groupId: group.id, user: item }))
          ]
          //console.log('onChange', data)
          setNewUserGroup(data)
        }}
        options={userOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => `${option.lastName || ''} ${option.firstName || ''} - ${option.email}`}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.lastName || ''} ${option.firstName || ''} - ${option.email}`}
          </React.Fragment>
        )}
        style={{ width: 400, margin: '1em 0' }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Add users"
            placeholder="Add users"
          />
        )}
      />
    )
  }

  return (!userGroupLoading && !usersLoading && !allUserLoading) && (
    <div>
      <Button aria-describedby={group.id} variant="contained" color="primary" onClick={handleClickOpen}>
        Manage Users
      </Button>
      <Dialog maxWidth={'md'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">User</DialogTitle>
        <DialogContent>
          {/* popup user table with dropdown options */}
          {userTable({ userGroup: newUserGroup })}
          {usersSelectionDropdown({ users: allUserData, group: usersData?.group })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose()
            setPopup(false)
          }} >
            Close
          </Button>
          <Button onClick={() => {
            saveUserGroup({
              groupId: group.id,
              data: newUserGroup.map(({ userId, groupId }) => ({ userId, groupId: group.id }))
            })
            setPopup(true)
            setPopupMessage('Saved')
          }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {popupMessasge({
        message: popupMessage,
        open: popup,
      })}

    </div>
  );

}
