import { makeStyles } from "@material-ui/core/styles";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { tableStyles } from "styles";
import ShowContractsBtn from "components/User/ShowContractsBtn"
import PermissionBtn from "components/Permission/PermissionBtn";
import { updateDwssUser } from "services/DwssUser";
import { deleteUser, useGetUsersByMyGroup } from "services/User";
import useSWR, { mutate } from "swr";

function UserListTable({
  members,
  setMember,
  setOpen,
  activateUser,
  suspendUser,
  statuses,
  groups,
}) {
  const useStyles = makeStyles((theme) => tableStyles(theme));
  const classes = useStyles();

  const { usersData, usersLoading, usersError } = useGetUsersByMyGroup();

  const getStatusName = (statusId) => {
    return statuses.find(({ id }) => id === statusId)["name"];
  };

  const getGroupName = (groupId) => {
    return groups.find(({ id }) => id === groupId)["name"];
  };

  const saveUserPermissions = async (data) => {
    const { userId, newDwssUserOrGroupPermissions } = data;
    if (!userId || !newDwssUserOrGroupPermissions) return
    //console.log('updating...', data)
    const response = await updateDwssUser(data);
    //console.log(response)

    mutate(`${process.env.REACT_APP_API}/api/dwss-permissions-by-user?userId=${userId}`);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="right">
              First name
            </TableCell>
            <TableCell className={classes.header} align="right">
              Last name
            </TableCell>
            <TableCell className={classes.header} align="right">
              Email
            </TableCell>
            <TableCell className={classes.header} align="right">
              Roles
            </TableCell>
            <TableCell className={classes.header} align="right">
              Organisation
            </TableCell>
            <TableCell className={classes.header} align="right">
              Contracts
            </TableCell>
            <TableCell className={classes.header} align="right">
              Permission
            </TableCell>
            <TableCell className={classes.header} align="right">
              Status
            </TableCell>
            <TableCell className={classes.header} align="right" style={{ width: '100px' }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!members &&
            members.map((member, i) => {

              return (
                <TableRow key={member.id}>
                  <TableCell align="right">{member.firstName}</TableCell>
                  <TableCell align="right">{member.lastName}</TableCell>
                  <TableCell align="right">{member.email}</TableCell>
                  <TableCell align="right">{member.role}</TableCell>
                  <TableCell align="right">
                    {member.groupId && getGroupName(member.groupId)}
                  </TableCell>
                  <TableCell align="right">
                    <ShowContractsBtn contracts={member.contracts} />
                  </TableCell>
                  <TableCell align="right">
                    {
                      usersData.group.name.toLowerCase() === 'master' ?
                        <PermissionBtn
                          displayName={member.username}
                          userId={member.id}
                          savePermissions={saveUserPermissions}
                        /> :
                        member.groupId === usersData.group.id ?
                          <PermissionBtn
                            displayName={member.username}
                            userId={member.id}
                            savePermissions={saveUserPermissions}
                          /> :
                          <></>
                    }
                  </TableCell>
                  <TableCell align="right">
                    {member.statusId && getStatusName(member.statusId)}
                  </TableCell>
                  <TableCell align="right">
                    <EditIcon
                      fontSize="small"
                      onClick={() => {
                        setMember({ ...member, index: i });
                        setOpen(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />

                    {Number(localStorage.userId) === member.id ? (
                      <></>
                    ) : (
                      <>
                        {/* delete icons */}
                        <Delete
                          fontSize="small"
                          onClick={async () => {
                            if (!window.confirm(`You are about to remove user ${member.lastName||''} ${member.firstName||''}`))
                              return
                            await deleteUser(member.id)
                            mutate(`${process.env.REACT_APP_API}/api/users/by-group`)
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        {/* suspend & unsuspend icons */}
                        {member.statusId === 1 && (
                          <BlockIcon
                            fontSize="small"
                            onClick={() => suspendUser({ ...member, index: i })}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {member.statusId === 2 && (
                          <CheckCircleOutlineIcon
                            fontSize="small"
                            onClick={() =>
                              activateUser({ ...member, index: i })
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserListTable;
