import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
// import ApiCall from "../utils/ApiCall";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
// import * as R from "ramda";
import {
  useGetContractsByMyGroup,
  // getAllContract,
  // getContractById,
  saveContract,
  // getContractByIdRics,
  // getContractByIdSiteDiary,
  // getContractByIdPhoto,
} from "services/Contract";
import { useGroups } from "services/Group";
import {
  getContractGroupByContractId,
  saveContractGroup,
} from "services/ContractGroup";
import { getStatuses } from "services/Status";
import { searchBoxStyles } from "styles";
import { ContractTable } from "components/Contract";
import useSWR, { mutate } from "swr";

export default function Permission() {
  const { contractsInfo, conractsLoading, contractsError } =
    useGetContractsByMyGroup();

  const { groupsData, groupsLoading, groupsError } = useGroups();
  const { data: statuses, error: statuseserror } = useSWR(
    `/api/statuses`,
    getStatuses,
  );
  //console.log("contracts", contractsInfo);
  //console.log("groups", groupsData);
  // console.log("statuses", statuses);
  const contracts = contractsInfo?.contracts;

  const useStyles = makeStyles((theme) => searchBoxStyles(theme));
  const classes = useStyles();

  const [newContract, setNewContract] = useState(false);
  // const [groups, setGroups] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState(null);
  const [contract, setContract] = useState({});
  // const [contracts, setContracts] = useState([]);
  // const [statuses, setStatuses] = useState([]);
  const [searchText, setSearchText] = useState("");

  if (!contracts || !groupsData || !statuses) return <div>loading...</div>;

  const handleSaveContractGroup = async (selectedGroups) => {
    console.log("selectedGroups", selectedGroups);
    if (!selectedGroups) return;
    const data = selectedGroups.map(({ id }) => ({
      contractId: contract.id,
      groupId: id,
    }));

    await saveContractGroup({ contractId: contract.id, data });
    mutate(`${process.env.REACT_APP_API}/api/contracts/by-group`);
  };

  const handleSaveContract = async (contract) => {
    //console.log("contract", JSON.stringify(contract));

    const result = await saveContract(contract);
    //console.log(result);
    mutate(`${process.env.REACT_APP_API}/api/contracts/by-group`);
  };

  const handleNewContract = () => {
    setContract({});
    setNewContract(true);
  };

  const searchContracts = (contracts) => {
    //console.log("??", contracts);
    const tuneString = (s) => (!s ? "" : s.toString().toLowerCase());

    const filteredMembers = contracts?.filter((contract) => {
      // console.log("?", Object.keys(member));
      return !!Object.keys(contract).find((key) =>
        tuneString(contract[key]).includes(tuneString(searchText)),
      );
    });

    return filteredMembers;
  };

  return (
    <div style={{ margin: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4>Permission</h4>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          {/*

          <Paper component="form" className={classes.root}>
            <SearchIcon />
            <InputBase
              name={searchText}
              className={classes.input}
              placeholder="Search Contract"
              inputProps={{ "aria-label": "search contract" }}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </Paper>
          <Button
            //disabled
            variant="contained"
            color="primary"
            onClick={handleNewContract}
          >
            + Contract
          </Button>
*/}
        </div>
      </div>

      <>module table here risc, sd, risk etc.</>
      <div>
        <table>
          <tr>
            <th>Module name</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td>RISC</td>
            <td><button>Edit User Permission</button></td>
            <td><button>Edit Group Permission</button></td>
          </tr>
          <tr>
            <td>Site Diary</td>
            <td><button>Edit User Permission</button></td>
            <td><button>Edit Group Permission</button></td>
          </tr>
          <tr>
            <td>Labour</td>
            <td><button>Edit User Permission</button></td>
            <td><button>Edit Group Permission</button></td>
          </tr>
          <tr>
            <td>RISK</td>
            <td><button>Edit User Permission</button></td>
            <td><button>Edit Group Permission</button></td>
          </tr>
        </table>
      </div>
      {/*
      <ContractTable
        contracts={searchContracts(contracts)}
        newContract={newContract}
        setNewContract={setNewContract}
        contract={contract}
        setContract={setContract}
        // setContracts={setContracts}
        statuses={statuses}
        groups={groupsData}
        handleSaveContractGroup={handleSaveContractGroup}
        handleSaveContract={handleSaveContract}
        selectedGroupIds={selectedGroupIds}
        setSelectedGroupIds={setSelectedGroupIds}
      />
*/}
    </div>
  );
}
