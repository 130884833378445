import axios from "axios";
const camelcaseKeys = require("camelcase-keys");

export default class ApiCall {
  static swrFetcher() {
    return (url) =>
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.accessToken}` },
        })
        .then(({ data }) => camelcaseKeys(data[Object.keys(data)[0]]))
        .catch(err => {
          // console.log('swrFetcher err:', err.response.status, err)
          if (err.response.status === 401)
            window.location.href = '/login';
        });
  }

  static async get(route) {
    try {
      const response = (
        await axios.get(route, {
          headers: { Authorization: `Bearer ${localStorage.accessToken}` },
        })
      ).data;
      return camelcaseKeys(response[Object.keys(response)[0]]);
    } catch (err) {
      console.dir(`ApiCall get err: ${err}`);
      if (err.response.status === 401)
        window.location.href = '/login';
      throw err.response?.data;
    }
  }

  static async post(route, data) {
    try {
      const response = (
        await axios.post(route, data, {
          headers: { Authorization: `Bearer ${localStorage.accessToken}` },
        })
      ).data;
      const result = camelcaseKeys(response[Object.keys(response)[0]]);

      return result;
    } catch (err) {
      console.dir(`ApiCall post err: ${err}`);
      throw err.response?.data;
    }
  }

  static async update(route, data) {
    try {
      if (!!data.id) {
        data.id = Number(data.id);
      }
      const response = (
        await axios.patch(route, data, {
          headers: { Authorization: `Bearer ${localStorage.accessToken}` },
        })
      ).data;

      const result = camelcaseKeys(response[Object.keys(response)[0]]);

      return result;
    } catch (err) {
      console.dir(`ApiCall update err: ${err}`);
      throw err.response?.data;
    }
  }

  static async delete(route) {
    try {
      const response = (
        await axios.delete(route, {
          headers: { Authorization: `Bearer ${localStorage.accessToken}` },
        })
      ).data;
      return camelcaseKeys(response[Object.keys(response)[0]]);
    } catch (err) {
      console.dir(`ApiCall delete err: ${err}`);
      throw err.response?.data;
    }
  }
}
